import axios from "axios";
import { useSelector } from "react-redux"; // Import the useSelector hook

const API = axios.create({
    // baseURL: "https://app.tradeyarr.com/api",
    // baseURL: "https://back.alphatrade.click/api",
    baseURL: "https://app.bullex.in/api",
    // baseURL: "https://app.alphastock.in/api",
    // baseURL: "https://app.tdmaster.in/api",
    // baseURL: "http://localhost:8000/api/"
    // baseURL: "https://trade-world.in/backend"
    // baseURL: "https://esttrades.in/backend"
    // baseURL: "https://ggtradebook.com/backend"
});

export default API;
